import {
    Component,
    OnInit,
    OnDestroy,
    EffectRef,
    effect,
    signal,
    ViewChild,
    AfterViewInit,
    ChangeDetectionStrategy
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    MapService,
    ConfigService,
    // LayerService,
    SidenavService,
    ExcelService,
    EditService,
    LegendService
} from 'app/_services';
import { DateAdapter } from '@angular/material/core';
import { environment } from 'environments/environment';
import { faFilePdf, faMapPin } from '@fortawesome/free-solid-svg-icons';
import * as FileSaver from 'file-saver';
import { FormService } from 'app/_services/form.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HistoryDialogComponent } from '../history/history.dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';
import {
    BehaviorSubject,
    catchError,
    debounceTime,
    Observable,
    skip,
    Subscription,
    switchMap
} from 'rxjs';

@Component({
    selector: 'form-overview',
    templateUrl: 'form-overview.component.html',
    styleUrls: ['form-overview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('expand', [
            state('collapsed,void', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            )
        ])
    ]
})
/**
 * @shouldRemove add description
 */
export class FormOverviewComponent implements OnInit, OnDestroy, AfterViewInit {
    // fontawsome icons
    readonly faFilePdf = faFilePdf;
    readonly faMapPin = faMapPin;
    readonly displayedColumns = ['created_by', 'updated_at'];
    readonly displayedColumnsWithExpand = [...this.displayedColumns, 'expand'];

    form: any;
    readonly env = environment;
    readonly total = signal(0);
    readonly pageSizeOptions = signal([]);
    readonly pageIndex = signal(1);
    readonly resultsPerPage = signal(25);
    readonly dataSource = signal<MatTableDataSource<any>>(
        new MatTableDataSource([])
    );
    readonly selectFilterValue = signal('');

    expandedElement;
    readonly filterOptions = signal([]);

    features: any[];
    values: any;
    configSubscription: EffectRef;

    form_value_id: string;

    readonly sortState = signal(undefined);
    private sortSubscription: Subscription = undefined;
    private readonly searchSubscription: Subscription;
    readonly searchInput = new BehaviorSubject<string>('');

    newRegisterCount = 0;

    @ViewChild(MatPaginator) readonly paginator: MatPaginator;
    @ViewChild(MatSort) readonly sort: MatSort;

    constructor(
        private readonly mapService: MapService,
        private readonly formService: FormService,
        private readonly excelService: ExcelService,
        private readonly sidenavService: SidenavService,
        private readonly editService: EditService,
        private readonly activatedRoute: ActivatedRoute,
        // private readonly layerService: LayerService,
        private readonly legendService: LegendService,
        private readonly configService: ConfigService,
        private readonly http: HttpClient,
        private readonly dateAdapter: DateAdapter<Date>,
        private readonly dialog: MatDialog
    ) {
        this.dateAdapter.setLocale('nl');

        this.searchSubscription = this.searchInput
            .pipe(
                debounceTime(300),
                skip(1),
                // distinctUntilChanged(),
                switchMap(() => this.performSearch()),
                catchError(error => {
                    console.error('Error fetching data:', error);
                    return error;
                })
            )
            .subscribe((result: any) => {
                this.pageIndex.set(1);
                this.dataSource.set(new MatTableDataSource(result.data));
                this.total.set(result.total);
            });

        this.configSubscription = effect(
            () => {
                const config = this.configService.config();
                if (!config) {
                    return;
                }

                // @Todo add support for multiple forms
                this.form = config.forms[0];
                this.search();

                if (!this.form.form_items) {
                    return;
                }

                this.form.form_items.forEach(item => {
                    console.log('item');
                    if (item.id === 113) {
                        console.log(item.selects);
                        this.filterOptions.set(item.selects);
                    }
                });
            },
            { allowSignalWrites: true }
        );

        this.sidenavService.setWidth(1000, 'px');

        this.form_value_id =
            this.activatedRoute.snapshot.paramMap.get('form_value_id');
        if (this.form_value_id) {
            setTimeout(() => {
                this.zoomTo(this.form_value_id);
            }, 3000);
        }
    }

    ngOnInit(): void {
        const extent = this.formService.formLayer.getSource().getExtent();

        if (extent && isFinite(extent[0])) {
            this.mapService
                .map()
                .getView()
                .fit(extent, {
                    size: this.mapService.map().getSize(),
                    padding: [200, 200, 300, 200],
                    duration: 1000
                });
        }
    }

    ngAfterViewInit() {
        this.sort.active = 'updated_at';
        this.sort.direction = 'desc';

        this.sortState.set({
            active: this.sort.active,
            direction: this.sort.direction
        });

        this.sortSubscription = this.sort.sortChange.subscribe(sortValue => {
            this.sortState.set(sortValue);
            this.search();
        });

        if (this.dataSource) {
            this.dataSource().paginator = this.paginator;
            this.dataSource().sort = this.sort;
        }
    }

    ngOnDestroy(): void {
        // this.layerService.notificationLayer().getSource().clear();
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
        if (this.sortSubscription) {
            this.sortSubscription.unsubscribe();
        }
    }

    zoomTo(id): void {
        const source = this.formService.formLayer.getSource();
        const feature = source.getFeatures().find(f => {
            return f.getProperties().register_id === id;
        });

        if (feature) {
            const extent = feature.getGeometry().getExtent();
            this.mapService
                .map()
                .getView()
                .fit(extent, {
                    size: this.mapService.map().getSize(),
                    padding: [200, 200, 300, 200],
                    duration: 1000,
                    maxZoom: 15
                });
        } else {
            console.log('Feature met ID ' + id + ' niet gevonden.');
        }
    }

    getItemValue(element, itemId) {
        const item = element.values.find(v => v.item_id === itemId);
        return item ? item.value : '';
    }

    download(value, name): void {
        const encodedValue = btoa(value);
        this.http
            .get(environment.api_base_url + '/download/' + encodedValue, {
                responseType: 'blob'
            })
            .subscribe(x => {
                value = value.substring(value.lastIndexOf('/') + 1);
                FileSaver.saveAs(x, name);
            });
    }

    exportExcel(): void {
        const exportNotifications = this.values;

        // Export as Excel
        this.excelService.exportAsExcelFile(exportNotifications, 'Export');
    }

    updateSearchInput(value: string): void {
        this.searchInput.next(value);
    }

    openHistoryDialog(id): void {
        const dialogRef = this.dialog.open(HistoryDialogComponent, {
            width: '50%',
            panelClass: 'customStylingDialog',
            data: {
                id
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
            }
        });
    }

    toggleRow(row) {
        row.expanded = !row.expanded;
    }

    openRow(row) {
        this.zoomTo(row.id);
        this.expandedElement = this.expandedElement === row ? null : row;
    }

    applyFilter(filterValue: string, filterType: string): void {
        if (filterType === 'search') {
            this.searchInput.next(filterValue);
        } else if (filterType === 'dropdown') {
            this.selectFilterValue.set(filterValue);
            this.search();
        }
    }

    edit(element) {
        this.sidenavService.tabIndex.set(1);

        const layer = this.legendService.findMap(
            'form_' + this.formService.form.id
        );

        this.editService.layer.set(layer);
        var feature = layer
            .getSource()
            .getFeatures()
            .find(f => {
                return f.getProperties().register_id === element.id;
            });

        this.editService.feature.set(feature);
    }

    private performSearch(): Observable<any> {
        const url = `${environment.api_base_url}/forms/search_value/${this.form.id}`;

        return this.http.get<any>(url, {
            params: {
                page: this.pageIndex().toString(),
                resultsPerPage: this.resultsPerPage().toString(),
                search: this.searchInput.getValue(),
                sortColumn: this.sortState()?.active,
                sortDirection: this.sortState()?.direction,
                selectFilterValue: this.selectFilterValue()
            }
        });
    }

    private search(): void {
        this.performSearch().subscribe({
            next: result => {
                this.dataSource.set(new MatTableDataSource(result.data));
                this.total.set(result.total);
                const size = this.total();
                const options = [10, 25, 50, 100, 200];
                const filteredOptions = options.filter(
                    option => option <= size
                );
                this.pageSizeOptions.set(filteredOptions);
            },
            error: error => {
                console.error(error);
            }
        });
    }
}
